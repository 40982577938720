import { IControllerConfig } from '@wix/native-components-infra/dist/src/types/types';
import {
  GroupListState,
  GroupSortFieldMapping,
  V1GroupsSortBy,
} from '@wix/social-groups-api';
import { BaseGroupsController } from '../../../common/controllers/groups-list/BaseGroupsController';
import { settingsParams } from '../../../common/settings/groups-list/settings';
import { UpdateProgress } from '../../../common/ContentEditor/UpdateProgress';
import { checkRTL } from '../../../common/utils/checkRTL';
import { GroupsPageProps } from '../types/groupsPageProps';
import { GroupsPageData } from '../Widget/App/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';
import { PubSubEventTypes } from 'Group/controllers/pubSub/PubSubEventTypes';

export class GroupsController extends BaseGroupsController<GroupsPageProps> {
  private sortByFromSettings: any;

  constructor(controllerContext: ControllerParams) {
    super(controllerContext);

    this.subscribe(
      PubSubEventTypes.GROUP_REQUEST_ACTION,
      async (payload: any) => {
        if (payload?.data?.approveGroupRequests) {
          await this.getGroups(controllerContext.controllerConfig.config);
          this.populateGroupsList();
        }
      },
    );
  }

  protected async getGroups(config: IControllerConfig) {
    // TODO: refactor for server-side query with sort
    await this.groupListModel.makeRequest();
    await this.groupListModel.sort((await this.getSortBy()) as any);
    return this.groupListModel.getGroups();
  }

  private async getSortBy() {
    const groupsSortBy = this.groupListModel.getGroupsSortBy();
    if (groupsSortBy) {
      return groupsSortBy;
    }
    const settings = await this.getGroupsInstanceSettings();
    const sortBy = !settings
      ? V1GroupsSortBy.RECENT_ACTIVITY
      : settings.groupsSortBy;
    return sortBy;
  }

  async updateConfig($w: any, updatedConfig: IControllerConfig): Promise<void> {
    await this.setApiSortingFromConfig(updatedConfig);
    return super.updateConfig($w, updatedConfig).catch((e: any) => {
      console.error('Error in [GroupsController.updateConfig]: ', e);
      this.flowAPI.errorMonitor.captureException(e);
    });
  }

  private async setApiSortingFromConfig(updatedConfig: IControllerConfig) {
    try {
      const settingsValuesNew = this.getSortByFromSettings();

      if (this.sortByFromSettings !== settingsValuesNew) {
        this.sortByFromSettings = settingsValuesNew;
        // TODO: api call is sent on every settings change, how to make one on site saved event?
        const apiSort = GroupSortFieldMapping[settingsValuesNew];
        await this.updateGroupInstanceSettings({
          groupsSortBy: apiSort,
        });
        await this.groupListModel.sort(apiSort); // TODO:💩
        this.setState({ groupsSortBy: apiSort });
      }
    } catch (e: any) {
      console.error('Error in [GroupsController.setApiSortingFromConfig]: ', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
  }

  protected getActions() {
    const actions = super.getActions();
    return {
      ...actions,
      searchGroups: this.searchGroups,
      sortGroups: this.sortGroups,
    };
  }
  async getInitialProps(): Promise<Partial<GroupsPageData>> {
    // Props for SSR
    try {
      const groups = await this.getGroups(this.config);
      this.cacheGroups(groups);
      const groupUrls = await this.getGroupUrls(groups);
      await this.getGroupsInstanceSettings();
      const groupListState: GroupListState = this.groupListModel.getState();
      // TODO: why do we need state in controller?
      this.state = {
        ...groupListState,
        isRTL: checkRTL(this.getSiteLanguage()),
        updateProgress: null,
        groupUrls,
        metaSiteId: this.getMetaSiteId(),
        dashboardLink: this.getDashboardLink(),
      } as any;
      return this.state;
    } catch (e: any) {
      console.error('Error in [GroupsController.getInitialProps]: ', e);
      this.flowAPI.errorMonitor.captureException(e);
    }
    this.sortByFromSettings = this.getSortByFromSettings();
    return {};
  }

  private getSortByFromSettings() {
    return this.controllerContext.flowAPI.settings.get(settingsParams.sortBy);
  }

  public searchGroups = async (groupNameQuery: string) => {
    await this.groupListModel.search(groupNameQuery);
    this.populateGroupsList();
  };

  sortGroups = async (
    sortBy: V1GroupsSortBy = V1GroupsSortBy.RECENT_ACTIVITY,
  ) => {
    await this.groupListModel.sort(sortBy);
    this.populateGroupsList();
  };

  private populateGroupsList() {
    const groupListState = this.groupListModel.getState();
    this.setState({ ...groupListState, updateProgress: UpdateProgress.STALE });
  }
}

export default GroupsController;
